<template>
  <v-row no-gutters dense ref="menu" class="menu">
    <v-col cols="12" v-if="isMobile">
      <div class="close">
        <v-btn icon @click="closeDrawer">
          <v-icon color="secondary">mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="logo">
        <div class="logo-text text-left">
          <router-link :to="{ name: 'BookSlot' }" class="secondary--text">
            <h2>Happy Harbour</h2>
            <h5>
              {{ getSelectedLocationName }}
            </h5>
          </router-link>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <div class="menu-items d-block d-lg-flex d-md-flex">
        <div class="menu-text">
          <router-link exact :to="{ name: 'BookSlot' }" class="secondary--text"
            >Book Now
          </router-link>
        </div>

        <div class="menu-text">
          <router-link exact :to="{ name: 'Bookings' }" class="secondary--text"
            >Bookings
          </router-link>
        </div>

        <div class="menu-text">
          <router-link exact :to="{ name: 'TimeSheet' }" class="secondary--text"
            >Time Sheet
          </router-link>
        </div>

        <div class="menu-text">
          <router-link exact :to="{ name: 'CakeSheet' }" class="secondary--text"
            >Cake Sheet
          </router-link>
        </div>

        <div class="menu-text" v-if="getUserData?.roleid === 1">
          <router-link
            exact
            :to="{ name: 'Analytics' }"
            class="secondary--text"
          >
            Analytics
          </router-link>
        </div>

        <div class="menu-text">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="primary--text"
                color="secondary"
                light
                v-bind="attrs"
                v-on="on"
              >
                {{ getUserData?.username.toUpperCase() }}
              </v-btn>
            </template>
            <v-list dense class="text-left" color="primary">
              <v-list-item
                :class="getSelectedLocation === location.id ? 'secondary' : ''"
                v-for="(location, index) in getLocations"
                :key="index"
                @click="setSelectedLocation(location.id)"
              >
                <v-list-item-content>
                  <v-list-item-title
                    :class="
                      getSelectedLocation === location.id
                        ? 'primary--text'
                        : 'secondary--text'
                    "
                  >
                    {{ location.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-title class="secondary--text">
                  Logout
                  <v-icon small color="secondary">mdi-power</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getAllLocationsByUserId } from "@/services/admin/booking.service";
import { mapGetters } from "vuex";

export default {
  name: "AppMenu",
  data() {
    return {};
  },
  created() {
    if (this.isLoggedIn) this.getAllLocationsByUserId();
  },
  watch: {},
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "getUserData",
      "getLocations",
      "getSelectedLocation",
      "getSelectedLocationName",
    ]),
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    setSelectedLocation(location) {
      this.$store.dispatch("setSelectedLocation", location);
    },
    async getAllLocationsByUserId() {
      getAllLocationsByUserId()
        .then(async (response) => {
          if (response.data.success) {
            let locations = response.data.data;
            this.$store.commit("setLocations", locations);
            this.$store.commit("setSelectedLocation", locations[0].id);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    closeDrawer() {
      this.$emit("closeDrawer");
    },
    async logout() {
      localStorage.removeItem("token");
      this.$store.commit("setIsLoggedIn", false);
      await this.$nextTick();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
<style scoped>
.menu .menu-items {
  justify-content: end;
  align-items: center;
}

.menu .menu-text {
  text-align: left;
}

.menu .menu-text a {
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: normal;
}
.menu .menu-text a:hover {
  border-bottom: 2px solid cadetblue;
}

.menu .menu-text + .menu-text {
  margin-left: 30px;
}

@media only screen and (max-width: 960px) {
  .menu {
    position: relative;
    padding: 80px 0 80px 30px;
  }

  .menu .close {
    position: absolute;
    top: 5%;
    left: 90%;
    transform: translate(-90%, -5%);
  }

  .menu .logo .logo-text {
    font-size: 20px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .menu .logo-text a {
    font-weight: 600;
    text-decoration: none;
  }

  .menu .menu-text + .menu-text {
    margin-left: 0;
    margin-top: 25px;
  }

  .menu .menu-text a {
    text-decoration: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: normal;
  }
}
</style>
